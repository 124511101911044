import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

function FallbackLoading() {

    return (
        <div style={{textAlign: 'center', marginTop: '200px'}}><CircularProgress disableShrink style={{color: '#038d8d'}}/></div>
    );
}

export default FallbackLoading;