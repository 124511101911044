import React from 'react';
import {ReactComponent as JoshuaLogoCommunitySvg} from "../../resources/svg/joshua-logo-community.svg";

function InternetExplorer() {

    return (
        <div style={{textAlign: 'center', marginTop: '10%', marginRight: '10%', marginLeft: '10%', backgroundColor: 'white'}}>
            <div style={{backgroundColor: '#013333'}}>
                <JoshuaLogoCommunitySvg style={{width: '250px', height: '47px', margin: '15px', marginLeft: '50px'}} />
            </div>
            <br />
            <br />
            <br />
            <br />
            <div style={{color: '#c62828'}}>
                <h3>
                    INTERNET EXPLORER &Egrave; UN BROWSER OBSOLETO
                </h3>
            </div>
            <br />
            <br />
            <h3>
                SI CONSIGLIA DI AGGIORNARE IL SISTEMA O CAMBIARE BROWSER E DI RIPROVARE
            </h3>
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default InternetExplorer;