import React from 'react';
import Grid from '@material-ui/core/Grid';

import {ReactComponent as JoshuaLogoSvg} from './../../resources/svg/joshua-logo.svg';
import {ReactComponent as AlmavivaLogoWhiteSvg} from './../../resources/svg/almaviva-logo-white.svg';

import './index.css';
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";

class Footer extends React.Component {

    /**
     * RENDER
     */
    render() {

        return (
            <div className="footer">

                {/*VERSIONE DESKTOP*/}
                <Hidden smDown>
                    <div style={{marginRight: '74px', marginLeft: '74px', marginTop: '30px', marginBottom: '15px'}}>
                        <Grid container alignItems="center" spacing={3}>

                            <Grid item sm={3} style={{textAlign: 'left'}}>
                                <div>
                                    <JoshuaLogoSvg style={{width: '200px'}} />
                                </div>
                            </Grid>
                            <Grid item sm={6}>
                                <Grid container alignItems="center">
                                    <Grid item sm={12}>
                                        <div style={{fontSize: '18px'}}><a href="https://www.cyberiskvision.com" className="cyber">Home</a></div>
                                    </Grid>
                                    {/*<Grid item sm={4}>*/}
                                    {/*    <div style={{fontSize: '18px'}}><a href="https://community.cyberiskvision.com" className="cyber">Community</a></div>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item sm={4}>*/}
                                    {/*    <div style={{fontSize: '18px'}}><a href="mailto:info@cyberiskvision.com" className="cyber">Contattaci</a></div>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Grid>
                            <Grid item sm={3} style={{textAlign: 'right'}}>
                                <div>
                                    <AlmavivaLogoWhiteSvg style={{width: '125px'}} />
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Divider style={{backgroundColor: '#323f45'}}/>
                        <br />
                        <a href="https://www.almaviva.it/it_IT/Note-legali" target="_blank" rel="noopener noreferrer" className="note-legali">NOTE LEGALI</a>
                        <br />
                        <span style={{color: '#f2ede4'}}>Copyright AlmavivA 2022</span>
                    </div>
                </Hidden>

                {/*VERSIONE MOBILE*/}
                <Hidden mdUp>
                    <div style={{marginRight: '74px', marginLeft: '74px', marginTop: '30px', marginBottom: '15px'}}>
                        <Grid container alignItems="center" spacing={3} direction="column">

                            <Grid item xs={12}>
                                <div>
                                    <JoshuaLogoSvg style={{width: '200px'}} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{fontSize: '18px'}}><a href="https://www.cyberiskvision.com" className="cyber">Home</a></div>
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <div style={{fontSize: '18px'}}><a href="https://community.cyberiskvision.com" className="cyber">Community</a></div>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <div style={{fontSize: '18px'}}><a href="mailto:info@cyberiskvision.com" className="cyber">Contattaci</a></div>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <div>
                                    <AlmavivaLogoWhiteSvg style={{width: '125px'}} />
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Divider style={{backgroundColor: '#323f45'}}/>
                        <br />
                        <a href="https://www.almaviva.it/it_IT/Note-legali" target="_blank" rel="noopener noreferrer" className="note-legali">NOTE LEGALI</a>
                        <br />
                        <span style={{color: '#f2ede4'}}>Copyright AlmavivA 2022</span>
                    </div>
                </Hidden>
            </div>
        );
    }
}

export default Footer;