import React from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

class PageNotFound extends React.Component {

    /**
     * COSTRUTTORE
     */
    // constructor(props) {
    //     super(props);
    // }

    /**
     * COMPONENT DID MOUNT
     */
    componentDidMount() {
    }

    /**
     * RENDER
     */
    render() {

        return (
            <div className="page-not-found">
                <br />
                <Grid container alignItems="center" spacing={0}>
                    <Grid item xs={1}/>
                    <Grid item xs={10} style={{textAlign: 'center'}}>
                        <Paper>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h1 style={{color: '#8c8681'}}>404</h1>
                            <h1 style={{color: '#8c8681'}}>Pagina non trovata</h1>
                            <br />
                            <br />
                            <br />
                            <br />
                        </Paper>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </div>
        );
    }
}

export default PageNotFound;