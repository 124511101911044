import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import {ReactComponent as JoshuaLogoCommunitySvg} from "../../../resources/svg/joshua-logo-community.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#013333',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

function MenuSuperiore() {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElSolution, setAnchorElSolution] = React.useState(null);
    const [anchorElCommunity, setAnchorElCommunity] = React.useState(null);
    const [anchorElSolutionMobile, setAnchorElSolutionMobile] = React.useState(null);
    const [anchorElCommunityMobile, setAnchorElCommunityMobile] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickSolution = (event) => {
        setAnchorElSolution(event.currentTarget);
    };

    const handleCloseSolution = () => {
        setAnchorElSolution(null);
    };

    const handleClickCommunity = (event) => {
        setAnchorElCommunity(event.currentTarget);
    };

    const handleCloseCommunity = () => {
        setAnchorElCommunity(null);
    };

    const handleClickSolutionMobile = (event) => {
        setAnchorElSolutionMobile(event.currentTarget);
    };

    const handleCloseSolutionMobile = () => {
        setAnchorElSolutionMobile(null);
    };

    const handleClickCommunityMobile = (event) => {
        setAnchorElCommunityMobile(event.currentTarget);
    };

    const handleCloseCommunityMobile = () => {
        setAnchorElCommunityMobile(null);
    };

    return (
        <AppBar position="static" className={classes.appBar}>

            {/*VERSIONE DESKTOP*/}
            <Hidden smDown>
                <Toolbar>
                    <JoshuaLogoCommunitySvg style={{height: '47px', margin: '15px', marginLeft: '50px'}} />
                    <Typography variant="h6" className={classes.title} style={{color: '#f2ede4'}}>
                    </Typography>
                    <div style={{fontSize: '20px', marginRight: '50px'}}>
                        <a href="https://www.cyberiskvision.com" className="nav-link f-s-18" style={{marginRight: '25px'}}>Vai a Joshua Cyberisk Vision</a>
                        {/*<a onClick={handleClickSolution} className="nav-link f-s-18" style={{marginRight: '15px', display: 'inline-flex', verticalAlign: 'middle'}}>Solution<ExpandMoreIcon style={{color: '#f2ede4'}}/></a>*/}
                        {/*<StyledMenu*/}
                        {/*    id="Solution-menu"*/}
                        {/*    anchorEl={anchorElSolution}*/}
                        {/*    keepMounted*/}
                        {/*    open={Boolean(anchorElSolution)}*/}
                        {/*    onClose={handleCloseSolution}*/}
                        {/*>*/}
                        {/*    <MenuItem onClick={handleCloseSolution}><a href="https://www.cyberiskvision.com/cyber-risk-status.html" className="nav-link-mobile">Cyber Risk Status</a></MenuItem>*/}
                        {/*    <MenuItem onClick={handleCloseSolution}><a href="https://www.cyberiskvision.com/cyber-footprint.html" className="nav-link-mobile">Cyber Footprint</a></MenuItem>*/}
                        {/*    <MenuItem onClick={handleCloseSolution}><a href="https://www.cyberiskvision.com/threat-tracker.html" className="nav-link-mobile">Threat Tracker</a></MenuItem>*/}
                        {/*    <MenuItem onClick={handleCloseSolution}><a href="https://www.cyberiskvision.com/data-breach-detector.html" className="nav-link-mobile">Data Breach Detector</a></MenuItem>*/}
                        {/*    <MenuItem onClick={handleCloseSolution}><a href="https://www.cyberiskvision.com/active-assessment.html" className="nav-link-mobile">Active Assessment</a></MenuItem>*/}
                        {/*    <MenuItem onClick={handleCloseSolution}><a href="https://www.cyberiskvision.com/advanced-threat-hunting.html" className="nav-link-mobile">Advanced Threat Hunting</a></MenuItem>*/}
                        {/*</StyledMenu>*/}
                        {/*<a href="https://cyberiskvision.com/compliance.html" className="nav-link f-s-18" style={{marginRight: '25px'}}>Compliance</a>*/}
                        {/*<a href="https://www.cyberiskvision.com/research.html" className="nav-link f-s-18" style={{marginRight: '25px'}}>Research</a>*/}
                        {/*<a href="https://www.cyberiskvision.com/joshua.html" className="nav-link f-s-18" style={{marginRight: '25px'}}>History</a>*/}
                        {/*<a href="https://www.cyberiskvision.com/faq.html" className="nav-link f-s-18" style={{marginRight: '25px'}}>FAQ</a>*/}
                        {/*<a onClick={handleClickCommunity} className="nav-link f-s-18" style={{marginRight: '15px', display: 'inline-flex', verticalAlign: 'middle'}}>Community<ExpandMoreIcon style={{color: '#f2ede4'}}/></a>*/}
                        {/*<StyledMenu*/}
                        {/*    id="Community-menu"*/}
                        {/*    anchorEl={anchorElCommunity}*/}
                        {/*    keepMounted*/}
                        {/*    open={Boolean(anchorElCommunity)}*/}
                        {/*    onClose={handleCloseCommunity}*/}
                        {/*>*/}
                        {/*    <MenuItem onClick={handleCloseCommunity}><a href="https://community.cyberiskvision.com" className="nav-link-mobile">Community Home</a></MenuItem>*/}
                        {/*    <MenuItem onClick={handleCloseCommunity}><a href="/check-me" className="nav-link-mobile">CheckME</a></MenuItem>*/}
                        {/*</StyledMenu>*/}
                        {/*<Route render={props => (*/}
                        {/*    <CurrentLocation path={props.location.pathname} mobile={false} />*/}
                        {/*)} />*/}
                    </div>
                </Toolbar>
            </Hidden>

            {/*VERSIONE MOBILE*/}
            <Hidden mdUp>
                <Toolbar>
                    <Grid container alignItems="center" spacing={0}>
                        <Grid item xs={6} style={{textAlign: 'left'}}>
                            <JoshuaLogoCommunitySvg style={{height: '34px', marginTop: '5px'}} />
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'right'}}>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <MenuIcon style={{color: 'white'}}/>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}><a href="https://www.cyberiskvision.com" className="nav-link-mobile">Vai a Joshua Cyberisk Vision</a></MenuItem>
                                {/*<MenuItem onClick={handleClose}><a onClick={handleClickSolutionMobile} className="nav-link-mobile" style={{display: 'inline-flex', verticalAlign: 'middle'}}>Solution<ExpandMoreIcon style={{color: 'black'}}/></a></MenuItem>*/}
                                {/*<MenuItem onClick={handleClose}><a href="https://cyberiskvision.com/compliance.html" className="nav-link-mobile">Compliance</a></MenuItem>*/}
                                {/*<MenuItem onClick={handleClose}><a href="https://www.cyberiskvision.com/research.html" className="nav-link-mobile">Research</a></MenuItem>*/}
                                {/*<MenuItem onClick={handleClose}><a href="https://www.cyberiskvision.com/joshua.html" className="nav-link-mobile">History</a></MenuItem>*/}
                                {/*<MenuItem onClick={handleClose}><a href="https://www.cyberiskvision.com/faq.html" className="nav-link-mobile">FAQ</a></MenuItem>*/}
                                {/*<MenuItem onClick={handleClose}><a onClick={handleClickCommunityMobile} className="nav-link-mobile" style={{display: 'inline-flex', verticalAlign: 'middle'}}>Community<ExpandMoreIcon style={{color: 'black'}}/></a></MenuItem>*/}
                                {/*<Route render={props => (*/}
                                {/*    <CurrentLocation path={props.location.pathname} mobile={true} onClick={handleClose}/>*/}
                                {/*)} />*/}
                            </Menu>
                            <Menu
                                id="Solution-menu-mobile"
                                anchorEl={anchorElSolutionMobile}
                                keepMounted
                                open={Boolean(anchorElSolutionMobile)}
                                onClose={handleCloseSolutionMobile}
                            >
                                <MenuItem onClick={handleCloseSolutionMobile}><a href="https://www.cyberiskvision.com/cyber-risk-status.html" className="nav-link-mobile">Cyber Risk Status</a></MenuItem>
                                <MenuItem onClick={handleCloseSolutionMobile}><a href="https://www.cyberiskvision.com/cyber-footprint.html" className="nav-link-mobile">Cyber Footprint</a></MenuItem>
                                <MenuItem onClick={handleCloseSolutionMobile}><a href="https://www.cyberiskvision.com/threat-tracker.html" className="nav-link-mobile">Threat Tracker</a></MenuItem>
                                <MenuItem onClick={handleCloseSolutionMobile}><a href="https://www.cyberiskvision.com/data-breach-detector.html" className="nav-link-mobile">Data Breach Detector</a></MenuItem>
                                <MenuItem onClick={handleCloseSolutionMobile}><a href="https://www.cyberiskvision.com/active-assessment.html" className="nav-link-mobile">Active Assessment</a></MenuItem>
                                <MenuItem onClick={handleCloseSolutionMobile}><a href="https://www.cyberiskvision.com/advanced-threat-hunting.html" className="nav-link-mobile">Advanced Threat Hunting</a></MenuItem>
                            </Menu>
                            <Menu
                                id="Community-menu-mobile"
                                anchorEl={anchorElCommunityMobile}
                                keepMounted
                                open={Boolean(anchorElCommunityMobile)}
                                onClose={handleCloseCommunityMobile}
                            >
                                <MenuItem onClick={handleCloseCommunityMobile}><a href="https://community.cyberiskvision.com" className="nav-link-mobile">Community Home</a></MenuItem>
                                <MenuItem onClick={handleCloseCommunityMobile}><a href="/check-me" className="nav-link-mobile">CheckME</a></MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Hidden>

        </AppBar>
    );

}

export default MenuSuperiore;