export default [
    {
        path: '/',
        pageComponent: 'home',
        exact: true,
        menuName: ''
    },
    {
        path: '/check-me',
        pageComponent: 'check-me',
        exact: true,
        menuName: 'CheckME'
    }
]

