import React, {Suspense} from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';

import {BrowserRouter, Route, Switch} from "react-router-dom";

import './App.css';
import Footer from "./components/footer";
import PageNotFound from "./components/page-not-found";

import Routes from "./router/routes"
import MenuSuperiore from "./components/menu-bar/menu-superiore";
import FallbackLoading from "./components/spinner/fallback-loading";

import { isIE } from 'react-device-detect'
import InternetExplorer from "./components/internet-explorer";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        flexGrow: 1,
    },
}));

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
});

function App() {

    const classes = useStyles();

    if (!isIE) {
        return (
            <ThemeProvider theme={theme}>
                <div className="App">
                    <BrowserRouter>
                        <div className={classes.root}>
                            <MenuSuperiore/>
                            <Suspense fallback={<FallbackLoading/>}>
                                <Switch>
                                    {Routes.map((route) => (
                                        <Route key={route.pageComponent} exact={route.exact} path={route.path}
                                               component={React.lazy(() => import('./pages/' + route.pageComponent))}/>
                                    ))}
                                    <Route component={PageNotFound}/>
                                </Switch>
                            </Suspense>
                        </div>
                        <Footer/>
                    </BrowserRouter>
                </div>
            </ThemeProvider>
        );
    }
    else{
        return (
            <InternetExplorer />
        )
    }
}

export default App;
